<template>
  <v-card>
    <v-card-title>
      <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
      <span class="subtitle-1">{{ $t('innerSideNav.allClaims') }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-6">
      <v-row justify="end" class="ma-0">
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
              v-model="search"
              :aria-label="$t('search')"
              :label="$t('search')"
              filled
              prepend-inner-icon="mdi-magnify"
              persistent-hint
              clearable
              :hint="$t('inputMessages.searchHint')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          v-model="selectedRequests"
          :headers="headers"
          :items="serviceRequests"
          :search="search"
          :sort-by="['createDate']"
          :sort-desc="['true']"
          :loading="loadingData"
          :loading-text="$t('loadingText')"
          no-data-text="Nem található leadott igény"
          show-select
          :no-results-text="`A keresett ${search} kifejezés nem található`"
      >
        <template v-slot:top>
          <v-row align="center" v-show="selectedRequests.length > 0" class="mx-0 pa-4">
            <v-btn color="success" @click="" small class="mr-4">
              {{ $t('button.acceptQuotes') }}
            </v-btn>
            <v-btn color="error" @click="" small>
              {{ $t('button.rejectQuotes') }}
            </v-btn>
          </v-row>
        </template>
        <template v-slot:item.serviceRequest.createDate="{item}">
          {{ item.serviceRequest.createDate }}
        </template>
        <template v-slot:item.serviceRequest.b2bRefNo="{item}">
          {{ item.serviceRequest.b2bRefNo ? `${item.serviceRequest.b2bRefNo}` : '-' }}
        </template>
        <template v-slot:item.serviceRequest.uniqueIdentifierIn1="{item}">
          {{ item.serviceRequest.uniqueIdentifierIn1 ? item.serviceRequest.uniqueIdentifierIn1 : item.serviceRequest.uniqueIdentifierIn2 ? item.serviceRequest.uniqueIdentifierIn2 : item.serviceRequest.uniqueIdentifierIn3 ? item.serviceRequest.uniqueIdentifierIn3 : '-' }}
        </template>
        <template v-slot:item.serviceRequest.warrantyTypeName="{item}">
          {{ item.serviceRequest.warrantyTypeName }}
        </template>
        <template v-slot:item.document="{item}">
          <v-menu offset-y v-if="item.hasAtLeastOneDocument">
            <template v-slot:activator="{on, attr}">
              <v-btn color="primary" v-bind="attr" v-on="on" small>
                {{ $t('button.view') }}
                <v-icon right dark>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list dense v-if="item.hasAtLeastOneDocument">
              <template v-for="(doc, index) in item.document">
                <v-list-item :key="index" @click="downloadFile(item.serviceRequest.id, doc)" v-if="doc.hash">
                  <v-list-item-avatar>
                    <v-icon dense>
                      mdi-file
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ doc.description }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list v-else dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('noDocumentToShow') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <template v-else>
            {{ $t('noDocumentToShow') }}
          </template>
        </template>
        <template v-slot:item.quote="{item}">
          <template v-if="item.quote.totalNetPrice">
            {{ item.quote.totalNetPrice.toLocaleString(`${$i18n.locale}`, { style: 'currency', currency: `${item.quote.currency}`}) }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template v-slot:item.btn="{item}">
          <v-row class="mx-0" align="center" justify="center" style="width: 100px;">
            <template v-if="item.serviceRequest.code === 'EXPERT_REPORT_DONE' || item.serviceRequest.code === 'QUOTE_DONE'">
              <v-btn icon color="success" @click="" class="mr-4" :disabled="selectedRequests.length > 0">
                <v-icon>
                  mdi-check-circle
                </v-icon>
              </v-btn>
              <v-btn icon color="error" @click="" :disabled="selectedRequests.length > 0">
                <v-icon>
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </template>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row align="center" v-show="selectedRequests.length > 0" class="mx-0 pa-4">
            <v-btn color="success" @click="" small class="mr-4">
              {{ $t('button.acceptQuotes') }}
            </v-btn>
            <v-btn color="error" @click="" small>
              {{ $t('button.rejectQuotes') }}
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AllRequestsPreAlert',
  props: {
    //
  },
  async beforeMount() {
    this.serviceRequests = await this.loadAllServiceRequests();
    if (this.serviceRequests && this.serviceRequests.length > 0) {
      await this.checkHasAtLeastOneDoument();
    }
    // console.log('vajon megszerezte a PREALERTES listát?', this.serviceRequests);
    this.loadingData = false;
  },
  mounted() {
    //
  },
  data() {
    return {
      search: null,
      headers: [
        { text: this.$t('arriveToService'), value: 'serviceRequest.createDate' },
        { text: this.$t('referenceNumber'), value: 'serviceRequest.b2bRefNo' },
        { text: this.$t('brand'), value: 'serviceRequest.brandName' },
        { text: this.$t('deviceType'), value: 'serviceRequest.productTypeName' },
        { text: this.$t('imeiSn'), value: 'serviceRequest.uniqueIdentifierIn1' },
        { text: this.$t('status'), value: 'serviceRequest.actualStatus' },
        { text: this.$t('inputLabels.warrantyType'), value: 'serviceRequest.warrantyTypeName' },
        { text: this.$t('documents'), value: 'document' },
        { text: this.$t('quotePrice'), value: 'quote' },
        { text: '', value: 'btn' },
      ],
      serviceRequests: [],
      loadingData: true,
      selectedRequests: [],
    };
  },
  methods: {
    async loadAllServiceRequests() {
      if (this.$store.state.authModule.user.serviceRequests) {
        return this.$store.state.authModule.user.serviceRequests;
      }
    },
    /* loadTableData() {
      if (this.$store.state.authModule.user.serviceRequests) {
        if (this.$store.state.authModule.user.serviceRequests.length > 0) {
          this.$store.state.authModule.user.serviceRequests.forEach(item => {
            let template = {};
            template.createdAt = item.createDate;
            template.id = item.id;
            template.brandName = item.brandName;
            template.productTypeName = item.productTypeName;
            template.identifier = item.uniqueIdentifierIn1;
            if (item.actualStatus === 'Készülék elhagyta szervízt') {
              template.actualStatus = 'Elfogadott árajánlat';
            }  else if (item.actualStatus === 'Lezárt') {
              template.actualStatus = 'Megjavítva';
            } else if (item.actualStatus === 'Átvett') {
              template.actualStatus = 'Megjavítva';
            } else if (item.actualStatus === 'Kisszállítás alatt') {
              template.actualStatus = 'Megjavítva';
            } else {
              template.actualStatus = item.actualStatus;
            }
            this.serviceRequests.push(template);
          })
        } else {
          this.serviceRequests = [];
        }
      } else {
        this.serviceRequests = []
      }
      this.loadingData = false;
    }, */
    seeOrderDetails(crmRequestId) {
      this.$router.push({ name: 'igeny-reszletei', params: { crmRequestId: crmRequestId} });
    },
    async downloadFile(serviceRequestId, document) {
      // console.log('serviceRequestId: ', serviceRequestId);
      // console.log('document: ', document);
      let fileHandler = {
        crmRequestId: serviceRequestId,
        table: document.table,
        hash: document.hash,
      }
      const respone = await this.$store.dispatch('downloadDocument', { form: fileHandler });
    },
    async checkHasAtLeastOneDoument() {
      for (let i = 0; i < this.serviceRequests.length; i++) {
        let request = this.serviceRequests[i];
        let index = request.document.findIndex(doc => doc.hash !== null);
        this.serviceRequests[i].hasAtLeastOneDocument = index >= 0;
        request = null;
      }
    },
  },
};
</script>

<style scoped>

</style>
