<template>
  <div v-frag>
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
        <span class="subtitle-1">{{ $t('innerSideNav.allClaims') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-row justify="end" class="ma-0">
          <v-col cols="12" sm="6" class="pa-0">
            <v-text-field
                v-model="search"
                :aria-label="$t('search')"
                :label="$t('search')"
                filled
                prepend-inner-icon="mdi-magnify"
                persistent-hint
                clearable
                :hint="$t('inputMessages.searchHint')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
            :locale="$i18n.locale"
            :headers="$store.state.authModule.user.profile.customerType === 'EMPLOYEE' ? headersEMPLOYEE : headers"
            :items="serviceRequests"
            :search="search"
            :sort-by="['createdAt']"
            :sort-desc="['true']"
            :loading="loadingData"
            :loading-text="$t('loadingText')"
            :no-data-text="$t('noDataText.noRequestFound')"
            :no-results-text="`${$t('noDataText.noSearchResult1')} ${search} ${$t('noDataText.noSearchResult2')}`"
        >
          <template v-slot:item.btn="{item}">
            <v-btn text color="primary" class="mr-2" @click="seeOrderDetails(item.id)">
              {{ $t('button.view') }}
            </v-btn>
            <v-btn v-if="item.isServiceWarranty === 'igen'"
                   text
                   color="success"
                   @click="openReUseDialog(item.id)">
              {{ $t('swPickup') }}
            </v-btn>
          </template>
          <!--template v-slot:item.szervizgar="{item}">
            <v-btn text color="error" @click="seeOrderDetails(item.id)">
              {{ $t('swPickup') }}
            </v-btn>
          </template-->
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- REUSEREQUEST DIALOG START -->
    <v-dialog v-model="reUseDialog.isVisible" max-width="440px" persistent>
      <v-card>
        <v-card-title>
          <v-icon color="primary" class="mr-4">mdi-alert-circle</v-icon>
          <span class="subtitle-1">{{ $t('reUseRequestDialog.title') }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <p>
            Amennyiben a készülék javításával probléma merült fel és a korábbi javítás után nem telt el hat hónap, úgy a tovább gomb megnyomásával az űrlapon a hiányzó adatok kitöltésével igényelhető a Szerviz által biztosított garanciális ügyintézés.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeReUseDialog">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="reUseTheRequest">
            {{ $t('button.next') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- REUSEREQUEST DIALOG END -->
  </div>
</template>

<script>
  export default {
    name: 'AllRequests',
    props: {
      //
    },
    async beforeMount() {
      await this.showAllCustomerRequests();
      await this.loadTableData();
      // setTimeout(this.loadTableData, 1500);
    },
    data() {
      return {
        search: null,
        headersEMPLOYEE: [
          { text: this.$t('order.orderDate'), value: 'createdAt' },
          { text: this.$t('order.orderId'), value: 'id' },
          { text: this.$t('gsmWorksheetId'), value: 'gsmWorksheetId' },
          { text: this.$t('brand'), value: 'brandName' },
          { text: this.$t('deviceType'), value: 'productTypeName' },
          { text: this.$t('imeiSn'), value: 'identifier' },
          { text: this.$t('status'), value: 'actualStatus' },
          { text: '', value: 'btn' },
        ],
        headers: [
          { text: this.$t('order.orderDate'), value: 'createdAt' },
          { text: this.$t('order.orderId'), value: 'id' },
          { text: this.$t('brand'), value: 'brandName' },
          { text: this.$t('deviceType'), value: 'productTypeName' },
          { text: this.$t('imeiSn'), value: 'identifier' },
          { text: this.$t('status'), value: 'actualStatus' },
          { text: '', value: 'btn' },
        ],
        desserts: [],
        serviceRequests: [],
        loadingData: true,
        reUseDialog: {
          isVisible: false,
          crmRequestId: null,
        },
      };
    },
    methods: {
      async showAllCustomerRequests() {
        await this.$store.dispatch('GetServiceRequests');
        await this.$store.dispatch('GetServiceRequests');
      },
      async loadTableData() {
        if (this.$store.state.authModule.user.serviceRequests) {
          if (this.$store.state.authModule.user.serviceRequests.length > 0) {
            for (let i = 0; i < this.$store.state.authModule.user.serviceRequests.length; i++) {
              let template = {};
              // const resp = await this.$store.dispatch('ServiceWarrantyRulesFits', this.$store.state.authModule.user.serviceRequests[i].id);
              // console.log('ServiceWarrantyRulesFits', resp);
              // template.isServiceWarranty = resp.data.serviceWarranty;
              template.isServiceWarranty = 'nem';
              // const mostServiceIds = [24, 25];
              const mostServiceIds = [25];
              // if (!(this.$store.state.authModule.user.serviceRequests[i].crmServiceId === 24 || this.$store.state.authModule.user.serviceRequests[i].crmServiceId === 25)) {
              if (!mostServiceIds.includes(this.$store.state.authModule.user.serviceRequests[i].crmServiceId)) {
                // console.log('this.$store.state.authModule.user.serviceRequests[i]: ', this.$store.state.authModule.user.serviceRequests[i]);
                template.createdAt = this.$store.state.authModule.user.serviceRequests[i].createDate;
                template.id = this.$store.state.authModule.user.serviceRequests[i].id;
                template.gsmWorksheetId = this.$store.state.authModule.user.serviceRequests[i].gsmWorksheetId;
                template.brandName = this.$store.state.authModule.user.serviceRequests[i].brandName;
                template.productTypeName = this.$store.state.authModule.user.serviceRequests[i].productTypeName;
                if (this.$store.state.authModule.user.serviceRequests[i].uniqueIdentifierIn1) {
                  template.identifier = this.$store.state.authModule.user.serviceRequests[i].uniqueIdentifierIn1;
                } else if (this.$store.state.authModule.user.serviceRequests[i].uniqueIdentifierIn2) {
                  template.identifier = this.$store.state.authModule.user.serviceRequests[i].uniqueIdentifierIn2;
                } else if (this.$store.state.authModule.user.serviceRequests[i].uniqueIdentifierIn3) {
                  template.identifier = this.$store.state.authModule.user.serviceRequests[i].uniqueIdentifierIn3;
                } else {
                  template.identifier = '-';
                }
                template.warrantyTypeName = this.$store.state.authModule.user.serviceRequests[i].warrantyTypeName;
                if (this.$store.state.authModule.user.serviceRequests[i].actualStatus === 'Készülék elhagyta szervízt') {
                  template.actualStatus = 'Elfogadott árajánlat';
                }  else if (this.$store.state.authModule.user.serviceRequests[i].actualStatus === 'Lezárt') {
                  template.actualStatus = 'Szerviz folyamat befejeződött';
                } else if (this.$store.state.authModule.user.serviceRequests[i].actualStatus === 'Átvett') {
                  template.actualStatus = 'Szerviz folyamat befejeződött';
                } else if (this.$store.state.authModule.user.serviceRequests[i].actualStatus === 'Kiszállítás alatt') {
                  template.actualStatus = 'Szerviz folyamat befejeződött';
                } else {
                  template.actualStatus = this.$store.state.authModule.user.serviceRequests[i].actualStatus;
                }
                this.serviceRequests.push(template);
              }
            }
          } else {
            this.serviceRequests = [];
          }
        } else {
          this.serviceRequests = []
        }
        this.loadingData = false;
      },
      seeOrderDetails(crmRequestId) {
        this.$router.push({ name: 'igeny-reszletei', params: { crmRequestId: crmRequestId} });
      },
      openReUseDialog(crmRequestId) {
        this.reUseDialog.isVisible = true;
        this.reUseDialog.crmRequestId = crmRequestId;
      },
      closeReUseDialog() {
        this.reUseDialog.isVisible = false;
        this.reUseDialog.crmRequestId = null;
      },
      reUseTheRequest() {
        this.$router.push({ name: 'szervizgarancia-belepett', params: { crmRequestId: this.reUseDialog.crmRequestId }});
        this.closeReUseDialog();
        /*if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
          this.$router.push({ name: 'dolgozoknak', query: { reuse: crmRequestId }});
        } else if (this.$store.state.authModule.user.profile.customerType === 'B2C') {
          this.closeReUseDialog();
        } else if (this.$store.state.authModule.user.profile.customerType === 'FLEET') {
          this.$router.push({ name: 'flottakezeles', query: { reuse: crmRequestId }});
        } else if (this.$store.state.authModule.user.profile.customerType === 'DISTRIBUTOR') {
          this.$router.push({ name: 'flottakezeles', query: { reuse: crmRequestId }});
        } else if (this.$store.state.authModule.user.profile.customerType === 'STORE_01') {
          this.$router.push({ name: 'flottakezeles', query: { reuse: crmRequestId }});
        } else if (this.$store.state.authModule.user.profile.customerType === 'RETAILER') {
          this.$router.push({ name: 'flottakezeles', query: { reuse: crmRequestId }});
        } else if (this.$store.state.authModule.user.profile.customerType === 'OTHER_01') {
          this.$router.push({ name: 'flottakezeles', query: { reuse: crmRequestId }});
        } else {
          //
        }*/
      },
    },
  };
</script>

<style scoped>

</style>
