import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-tablet-cellphone")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t('innerSideNav.allClaims')))])],1),_c(VDivider),_c(VCardText,{staticClass:"pa-6"},[_c(VRow,{staticClass:"ma-0",attrs:{"justify":"end"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"aria-label":_vm.$t('search'),"label":_vm.$t('search'),"filled":"","prepend-inner-icon":"mdi-magnify","persistent-hint":"","clearable":"","hint":_vm.$t('inputMessages.searchHint')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"locale":_vm.$i18n.locale,"headers":_vm.$store.state.authModule.user.profile.customerType === 'EMPLOYEE' ? _vm.headersEMPLOYEE : _vm.headers,"items":_vm.serviceRequests,"search":_vm.search,"sort-by":['createdAt'],"sort-desc":['true'],"loading":_vm.loadingData,"loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText.noRequestFound'),"no-results-text":((_vm.$t('noDataText.noSearchResult1')) + " " + _vm.search + " " + (_vm.$t('noDataText.noSearchResult2')))},scopedSlots:_vm._u([{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.seeOrderDetails(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.view'))+" ")]),(item.isServiceWarranty === 'igen')?_c(VBtn,{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.openReUseDialog(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('swPickup'))+" ")]):_vm._e()]}}])})],1)],1),_c(VDialog,{attrs:{"max-width":"440px","persistent":""},model:{value:(_vm.reUseDialog.isVisible),callback:function ($$v) {_vm.$set(_vm.reUseDialog, "isVisible", $$v)},expression:"reUseDialog.isVisible"}},[_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-alert-circle")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t('reUseRequestDialog.title')))])],1),_c(VDivider),_c(VCardText,{staticClass:"pa-4"},[_c('p',[_vm._v(" Amennyiben a készülék javításával probléma merült fel és a korábbi javítás után nem telt el hat hónap, úgy a tovább gomb megnyomásával az űrlapon a hiányzó adatok kitöltésével igényelhető a Szerviz által biztosított garanciális ügyintézés. ")])]),_c(VCardActions,{staticClass:"pa-4"},[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.closeReUseDialog}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.reUseTheRequest}},[_vm._v(" "+_vm._s(_vm.$t('button.next'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }