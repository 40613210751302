<template>
  <v-container fluid>
    <!-- NEM PREALERTES USER START -->
    <v-container fluid v-if="$store.state.authModule.user.systemParams.customerType !== 'PREALERT'">
      <v-row>
        <v-col cols="12">
          <all-requests></all-requests>
        </v-col>
      </v-row>
    </v-container>
    <!-- NEM PREALERTES USER END -->
    <!-- PREALERT USER TÁBLÁZAT START -->
    <v-container v-else fluid class="px-6">
      <v-row>
        <v-col cols="12">
          <all-requests-pre-alert></all-requests-pre-alert>
        </v-col>
      </v-row>
    </v-container>
    <!-- PREALERT USER TÁBLÁZAT END -->
  </v-container>
</template>

<script>
import AllRequestsPreAlert from 'src/components/orders/AllRequestsPreAlert.vue';
import AllRequests from "../../components/orders/AllRequests.vue";

export default {
  name: 'InnerRequests',
  props: {
    //
  },
  components: {
    AllRequests, AllRequestsPreAlert,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
