import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-tablet-cellphone")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.$t('innerSideNav.allClaims')))])],1),_c(VDivider),_c(VCardText,{staticClass:"pa-6"},[_c(VRow,{staticClass:"ma-0",attrs:{"justify":"end"}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"aria-label":_vm.$t('search'),"label":_vm.$t('search'),"filled":"","prepend-inner-icon":"mdi-magnify","persistent-hint":"","clearable":"","hint":_vm.$t('inputMessages.searchHint')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.serviceRequests,"search":_vm.search,"sort-by":['createDate'],"sort-desc":['true'],"loading":_vm.loadingData,"loading-text":_vm.$t('loadingText'),"no-data-text":"Nem található leadott igény","show-select":"","no-results-text":("A keresett " + _vm.search + " kifejezés nem található")},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRequests.length > 0),expression:"selectedRequests.length > 0"}],staticClass:"mx-0 pa-4",attrs:{"align":"center"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"success","small":""},on:{"click":function($event){}}},[_vm._v(" "+_vm._s(_vm.$t('button.acceptQuotes'))+" ")]),_c(VBtn,{attrs:{"color":"error","small":""},on:{"click":function($event){}}},[_vm._v(" "+_vm._s(_vm.$t('button.rejectQuotes'))+" ")])],1)]},proxy:true},{key:"item.serviceRequest.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serviceRequest.createDate)+" ")]}},{key:"item.serviceRequest.b2bRefNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serviceRequest.b2bRefNo ? ("" + (item.serviceRequest.b2bRefNo)) : '-')+" ")]}},{key:"item.serviceRequest.uniqueIdentifierIn1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serviceRequest.uniqueIdentifierIn1 ? item.serviceRequest.uniqueIdentifierIn1 : item.serviceRequest.uniqueIdentifierIn2 ? item.serviceRequest.uniqueIdentifierIn2 : item.serviceRequest.uniqueIdentifierIn3 ? item.serviceRequest.uniqueIdentifierIn3 : '-')+" ")]}},{key:"item.serviceRequest.warrantyTypeName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serviceRequest.warrantyTypeName)+" ")]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.hasAtLeastOneDocument)?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attr,false),on),[_vm._v(" "+_vm._s(_vm.$t('button.view'))+" "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[(item.hasAtLeastOneDocument)?_c(VList,{attrs:{"dense":""}},[_vm._l((item.document),function(doc,index){return [(doc.hash)?_c(VListItem,{key:index,on:{"click":function($event){return _vm.downloadFile(item.serviceRequest.id, doc)}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" mdi-file ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(doc.description)+" ")])],1)],1):_vm._e()]})],2):_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('noDocumentToShow'))+" ")])],1)],1)],1)],1):[_vm._v(" "+_vm._s(_vm.$t('noDocumentToShow'))+" ")]]}},{key:"item.quote",fn:function(ref){
var item = ref.item;
return [(item.quote.totalNetPrice)?[_vm._v(" "+_vm._s(item.quote.totalNetPrice.toLocaleString(("" + (_vm.$i18n.locale)), { style: 'currency', currency: ("" + (item.quote.currency))}))+" ")]:[_vm._v(" - ")]]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c(VRow,{staticClass:"mx-0",staticStyle:{"width":"100px"},attrs:{"align":"center","justify":"center"}},[(item.serviceRequest.code === 'EXPERT_REPORT_DONE' || item.serviceRequest.code === 'QUOTE_DONE')?[_c(VBtn,{staticClass:"mr-4",attrs:{"icon":"","color":"success","disabled":_vm.selectedRequests.length > 0},on:{"click":function($event){}}},[_c(VIcon,[_vm._v(" mdi-check-circle ")])],1),_c(VBtn,{attrs:{"icon":"","color":"error","disabled":_vm.selectedRequests.length > 0},on:{"click":function($event){}}},[_c(VIcon,[_vm._v(" mdi-close-circle ")])],1)]:_vm._e()],2)]}},{key:"footer",fn:function(){return [_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedRequests.length > 0),expression:"selectedRequests.length > 0"}],staticClass:"mx-0 pa-4",attrs:{"align":"center"}},[_c(VBtn,{staticClass:"mr-4",attrs:{"color":"success","small":""},on:{"click":function($event){}}},[_vm._v(" "+_vm._s(_vm.$t('button.acceptQuotes'))+" ")]),_c(VBtn,{attrs:{"color":"error","small":""},on:{"click":function($event){}}},[_vm._v(" "+_vm._s(_vm.$t('button.rejectQuotes'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.selectedRequests),callback:function ($$v) {_vm.selectedRequests=$$v},expression:"selectedRequests"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }